import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import Subnavigation from '@interness/web-core/src/components/structure/Subnavigation/Subnavigation';
import { menu } from '../../data/service-menu';
export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "service-header"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1400, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Img = makeShortcode("Img");
const Wrapper = makeShortcode("Wrapper");
const CTASection = makeShortcode("CTASection");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Subnavigation menu={menu} menuTitle='Atelier Service' mdxType="Subnavigation" />
    <HeaderImage mdxType="HeaderImage">
    <Img fluid={props.data.headerImages.images[0].file.localFile.childImageSharp.fluid} alt='Events' mdxType="Img" />
    </HeaderImage>
    <h1>{`Service`}</h1>
    <Wrapper mdxType="Wrapper">
Unsere Goldschmiedemeister stehen Ihnen mit ihrem Wissen und ihrem handwerklichen Können auf höchstem Niveau bei allen Anliegen rund um Ihren Schmuck zur Verfügung. Folgende Leistungen bieten wir an:
      <Container fluid mdxType="Container">
  <Row mdxType="Row">
    <Col md={6} mdxType="Col">
      <h3>Armbanduhren</h3>
      <ul>
        <li>mechanische Uhren / Handaufzug</li>
        <li>Quarzarmbanduhren</li>
        <li>Automatikuhren</li>
        <li>Batteriewechsel</li>
        <li>Komplette Revisionen</li>
        <li>Glasersatz</li>
        <li>Leder-, Metall-, Kuststoffuhrarmbänder</li>
        <li>Bandanpassung</li>
        <li>Wasserdichtigkeitstest bis 10 Bar</li>
      </ul>
    </Col>
    <Col md={6} mdxType="Col">
      <h3>Goldschmiede Service</h3>
      <ul>
        <li>Goldschmiedereparaturen aller Art</li>
        <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
        <li>Ringgrößen ändern</li>
        <li>Perlenketten neu fassen oder knoten</li>
        <li>Trauringumarbeitung</li>
        <li>Neuanfertigungen</li>
        <li>Schmuck reinigen und aufarbeiten</li>
        <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
        <li>Laserschweißen</li>
      </ul>
    </Col>
  </Row>
  <Row mdxType="Row">
    <Col md={6} mdxType="Col">
      <h3>Altgoldankauf</h3>
      <ul>
        <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an</li>
        <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken</li>
        <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen</li>
        <li>Altgoldankauf ist Vertrauenssache</li>
      </ul>
    </Col>
    <Col md={6} mdxType="Col">
      <h3>Großuhren</h3>
      <ul>
        <li>Heim- und Tischuhren, Jahresuhren</li>
      </ul>
    </Col>
  </Row>
      </Container>
    </Wrapper>
    <hr></hr>
    <CTASection mdxType="CTASection" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      